import React, { useReducer } from "react";
import AppReducer from "./AppReducer";
import AppContext from "./AppContext";
import { SET_TOKEN, UPDATE_PROGRESS, SET_THEME_MODE } from "./AppType";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { environment } from "../config";

const AppState = (props) => {
  let initialState = {
    token: "",
    email: "",
    interview_data: null,
    uploadProgress: [],
    isDarkMode: false,
  };

  const setToken = async (token) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    var decoded = jwt_decode(token);
    sessionStorage.setItem("ainterviewtoken", token);
    let res = await axios.get(environment.getApplicant);
    console.log("resssssss", res, environment.getApplicant);

    const responseData = res.data.data;
    if (responseData) {
      if (responseData.assessment_type) {
        // Define the reading time and time duration values
        const readingTime = "10"; // Example reading time
        const questionTimeDuration = "30"; // Example time duration

        // Iterate through each question and add the keys
        if (
          responseData.interview_questions &&
          Array.isArray(responseData.interview_questions)
        ) {
          responseData.interview_questions.forEach((question) => {
            // Add reading_time and time_duration to each question
            question.reading_time = readingTime;
            question.time_duration =
              question.question_type === "mcq" ? questionTimeDuration : "60";
          });
        }

        // Dispatch the updated responseData
        dispatch({
          type: SET_TOKEN,
          payload: { token, interview_data: responseData },
        });
      } else {
        // Dispatch without modifying responseData
        dispatch({
          type: SET_TOKEN,
          payload: { token, interview_data: responseData },
        });
      }
    }
  };

  const setUploadProgress = async (index, data) => {
    if (state.uploadProgress[index]) state.uploadProgress[index] = data;
    else state.uploadProgress.push(data);
    dispatch({ type: UPDATE_PROGRESS, payload: state.uploadProgress });
  };

  const setThemeMode = async (isSettingDarkMode) => {
    state.isDarkMode = isSettingDarkMode;
    // let mybody = document.getElementsByTagName("body")[0];
    // if(isSettingDarkMode){
    //     mybody.style.backgroundColor = "black";
    //     mybody.style.color = "white";
    // }else{
    //     mybody.style.backgroundColor = "white";
    //     mybody.style.color = "black";
    // }
    dispatch({ type: SET_THEME_MODE, payload: state.isDarkMode });
  };

  const [state, dispatch] = useReducer(AppReducer, initialState);
  return (
    <AppContext.Provider
      value={{
        token: state.token,
        interview_data: state.interview_data,
        uploadProgress: state.uploadProgress,
        isDarkMode: state.isDarkMode,
        setThemeMode,
        setUploadProgress,
        setToken,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
